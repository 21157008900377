import React from 'react';
import { graphql } from "gatsby";
import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import ContentHeaderImageBtn from "@components/modules/content-header-image/content-header-image-btn";
import ContentHeaderImage from "@components/modules/content-header-image/content-header-image";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

import ArticleText from "@components/article-text/";
import MoreAgencyPosts from "@components/modules/more-agency-posts/";
import ModuleRender from "@components/modules/module-render/";
import FloatingBtn from "@components/floating-book-btn/";
import ImageTextModule from "@components/modules/image-text/";

const ArticlePages = ({ pageContext, data, location }, props) => {
    let headerClass;
    if (data.strapiAgencyMentors.BannerImage.headerType === 'HeaderImageLinks') {
        headerClass = " header-transparent header-transparent-cta";
    } else if (data.strapiAgencyMentors.BannerImage.headerType === 'HeaderImageButtons') {
        headerClass = " header-transparent";
    } else {
        headerClass = "";
    }

    // console.log ("asdasdas", data.strapiAgencyMentors)

    return (
        <Layout>
            <SEO title={data.strapiAgencyMentors.Pagename} />

            <Header classNameHeader={headerClass} />

            {(data.strapiAgencyMentors.BannerImage.headerType === 'HeaderImageLinks') &&
                <ContentHeaderImage
                    Title={data.strapiAgencyMentors.Title}
                    imgUrl={data.strapiAgencyMentors.BannerImage.Image?.childImageSharp?.fluid}
                    ButtonData={data.strapiAgencyMentors.BannerImage.Button}
                    pageName_1="Employers"
                    pageLink_1="/employers/"
                    pageName_2="Crux Mentors"
                    pageLink_2={`/employers/crux-mentors/`}
                    pageName_3={data.strapiAgencyMentors.Title}
                    lastClass_3="last"
                    HideBreadcrumbs={data.strapiAgencyMentors.BannerImage.HideBreadcrumbs}
                    showDate={data.strapiAgencyMentors.showDate}
                    showShare={data.strapiAgencyMentors.showShare}
                    publishDate={data.strapiAgencyMentors.publishDate}
                    pageLocation={location}
                    pageTitle={data.strapiAgencyMentors.Title}
                    desktopImageAlign={data.strapiAgencyMentors.BannerImage.desktopImageAlign}
                    tabletImageAlign={data.strapiAgencyMentors.BannerImage.tabletImageAlign}
                    mobileImageAlign={data.strapiAgencyMentors.BannerImage.mobileImageAlign}
                />
            }
            {(data.strapiAgencyMentors.BannerImage.headerType === 'HeaderImageButtons') &&
                <ContentHeaderImageBtn
                    Title={data.strapiAgencyMentors.Title}
                    imgUrl={data.strapiAgencyMentors.BannerImage.Image?.childImageSharp?.fluid}
                    ButtonData={data.strapiAgencyMentors.BannerImage.Button}
                    pageName_1="Employers"
                    pageLink_1="/employers/"
                    pageName_2="Crux Mentors"
                    pageLink_2={`/employers/crux-mentors/`}
                    pageName_3={data.strapiAgencyMentors.Title}
                    lastClass_3="last"
                    HideBreadcrumbs={data.strapiAgencyMentors.BannerImage.HideBreadcrumbs}
                    desktopImageAlign={data.strapiAgencyMentors.BannerImage.desktopImageAlign}
                    tabletImageAlign={data.strapiAgencyMentors.BannerImage.tabletImageAlign}
                    mobileImageAlign={data.strapiAgencyMentors.BannerImage.mobileImageAlign}
                />
            }
            {data.strapiAgencyMentors.articleText &&
                <ArticleText
                    Title={data.strapiAgencyMentors.articleText ? data.strapiAgencyMentors.articleText.Title : null}
                    Sub_Title={data.strapiAgencyMentors.articleText ? data.strapiAgencyMentors.articleText.Sub_Title : null}
                    text={data.strapiAgencyMentors.articleText ? data.strapiAgencyMentors.articleText.articleText : null}
                    membersData={data.strapiAgencyMentors.teams ? data.strapiAgencyMentors.teams : []}
                    sidebarData={data.strapiAgencyMentors.sidebar}
                    pageLocation={location}
                    pageTitle={data.strapiAgencyMentors.Title}
                    Date_Title={data.strapiAgencyMentors.articleText ? data.strapiAgencyMentors.articleText?.Dates?.Date_Title : null}
                    Date_Description={data.strapiAgencyMentors.articleText ? data.strapiAgencyMentors.articleText?.Dates?.Date_Description : null}
                />
            }
            <FloatingBtn />
            {data.allStrapiAgencyMentors?.edges?.length > 1 &&
                <div className="section-more-blog-posts-background">
                    <MoreAgencyPosts
                        sectionTitle="Similar Courses"
                        excludeId={data.strapiAgencyMentors.id}
                        allStrapiAgencyMentors={data.allStrapiAgencyMentors}
                    />
                </div>
            }
            {data.strapiAgencyMentors.selectModules &&
                <ModuleRender
                    moduleData={data.strapiAgencyMentors.selectModules}
                />
            }
            <ImageTextModule />
            <PopularSearch {...props} />
            <Footer />

        </Layout>
    );
}

export default ArticlePages

export const query = graphql`
query AgencyQuery($slug: String!) {
    strapiAgencyMentors(Slug: {eq: $slug}, Published: {eq: true}) {
        id
        Pagename
        Slug
        Title
        publishDate
        showDate
        showShare
        category {
            Slug
            name
        }
        BannerImage {
            id
            
            headerType
            HideBreadcrumbs
            Button {
                id
                Label

                page {
                    Slug
                }
            }
            Image {
                internal {
                    description
                }
                childImageSharp {
                    fluid(quality: 90, toFormat: JPG, maxWidth: 1570, maxHeight: 582, cropFocus: CENTER, fit: INSIDE, background: "#fff") {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        articleText {
            Title
            articleText
            Sub_Title
            Dates {
                Date_Title
                Date_Description
            }
        }
        sidebar {
            hideSaveBtn
            hideModuleBg
            sidebarButtons {
                Label
                id
                targetLink
                

            }
            sidebarVideo {
                id
                showVideo
                videoask
                videoChannel
                videoId
                
                posterImage {
                    internal {
                        description
                    }
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 400) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }

        selectModules {
            id
            modules {
                id
                moduleName
                component
                
                moduleType {
                    id
                    Title
                    Text


                }
            }
        }
    }
    allStrapiAgencyMentors(filter: {Published: {eq: true}}) {
        edges {
            node {
                BannerImage {
                    Image {
                        internal {
                            description
                        }
                        childImageSharp {
                            id
                            fluid(quality: 90, maxWidth: 400, maxHeight: 240, cropFocus: CENTER, fit: COVER, background: "#fff") {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                id
                Slug
                Title
                articleText {
                    articleText
                }
                category {
                    name
                    labelColour
                    labelFontColour
                    labelName
                    Slug
                }
            }
        }
    }
}
`